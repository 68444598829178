import startRoutes from "./start-router";
import praxisRoutes from "./praxis-router";
import kontaktRoutes from "./kontakt-router";
import leistungenRoutes from "./leistungen-router";
import termineRoutes from "./termine-router";
import impressumRoutes from "./impressum-router";
import datenschutzRoutes from "./datenschutz-router";

const routes = [...startRoutes,
                ...praxisRoutes,
                ...kontaktRoutes,
                ...leistungenRoutes,
                ...termineRoutes,
                ...datenschutzRoutes,
                ...impressumRoutes,
            ];

export default routes;
