//import store from "../../store";
const leistungenRoutes = [
  {
    path: "/Leistungen",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Leistungen.vue"
      ), 
      
  },
];

export default leistungenRoutes;
