<template>
  <div>
    <!--   <router-view v-slot="{ Component }">
    <transition
      :enter-active-class="`animate__animated animate__${$route.meta.enterTransition}`"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <component :is="Component" :key="$route.path"></component>
    </transition>
    </router-view>-->
    <router-view></router-view>
    <widget-container-modal />
  </div>
</template>

<script>
//import VueCookies from "vue-cookies";
import { container } from "jenesius-vue-modal";
//import { openModal } from "jenesius-vue-modal";
//import { mapState } from "vuex"







export default {
  name: "App",
  computed: {
    token() {
      return this.$store.getters.token;
    },
    
  },
  components: {
    WidgetContainerModal: container,
  },
  methods: {
    
  },
  created() {
    
    
  },
  mounted() {
    
  },
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~animate.css/animate.min.css";
@import "./eigen_css/kito.css";

.bgPicArtz{
  background-image: url("assets/artz.jpg");
    
     min-width: 100%;
  height: auto;
  background-size: cover; /* Resize the background image to cover the entire container */
}
.bg-Hellgrau {
  background-color: #CECFCB;
}
.bg-Dunkelgrau {
  background-color: #8A8A8A;
}
.bg-HellGruen {
  background-color: #90B4AF;
}

.bg-Weiss {
  background-color: white;
}
.text-Dunkelgrau{
  color: #5D595B;
}
.text-Rot{
  color: #A72527;
}
.bg-Rot{
  background-color: #A72527;
}
.text-Schwarz{
  color: black;
}
.text-Weiss{
  color: white;
}
.text-Hellgrau{
  color: #CECFCB;
}
.text-Shadow {
  text-shadow: 0px 3px 0px #b2a98f,
                 0px 14px 10px rgba(0,0,0,0.15);
}
.nav {
  background-color: rgb(255, 255, 255);
   color: black !important;
}
.nav-active {
    font-size: 22px;
  color: #A72527!important;
  text-decoration: underline white;
}
.nav-deactiv {
    font-size: 22px;
  text-decoration: none;
  color: black !important;
}

.navFooter-active {
    font-size: 22px;
  color: white !important;
  text-decoration: underline white;
}
.navFooter-deactiv {
    font-size: 22px;
  text-decoration: none;
  color: white !important;
}
.footer {
  background-color:#A72527;
   color: white !important;   
}
.center {
  margin: auto;
  width: 50%;
  padding: 20px;
}
.imgRoundet{
  border-radius: 18px;
}

.praxisBackground {
  background-image: linear-gradient(to top, #CECFCB 0%, white 50%);
  /*  background-image: url(assets/slider2.jpg);*/
    background-size: cover;
}
.praxisFfA {
    font-size: 12px;
}
.praxisBotsch {
    font-size: 20px;

}
.nehmenSiePlatz{
max-height: 50px; 
max-width: 600px;                   
}

.HerzlichWillkommen{
 height:120px; 
width: 400px;
} 


/* klein*/
@media screen and (max-width: 900px) {
  
.logoObenLinks{  
  background-color: white;
  max-height: 100%;  
  max-width: 100%;  
  object-fit: fill;  
  object-position: center ;  
  padding: 20px;
}
.bildKasten{
  min-width: 100%;
}
.fuerGross{
  display: none;
}
.header_Justify{
    justify-content: center!important;
}
}
/* Gross */ 
@media screen and (min-width: 900px) {
 
 .kastentext{
    font-size: 18px;
 }
 .header_Justify{  
    justify-content: flex-end!important;
}
 .logoObenLinks{  
  position: absolute;
  background-color: white;
  top: 10;
  left:  120px;
}

.logoObenLinks{  
  background-color: white;
  
  height: 100px;  
  object-fit: fill;  
  object-position: left top;  
  padding: 10px;
}
.botsch{
  max-height: 100%;
    margin: 50px;
}
.textweiss, .textgrau {
  padding: 10px;
  margin: 30px ;
}
.kastengrau{
  margin: 50px ;
}
.bildKasten{
  margin: 50px ;
}

.fuerKlein{
  display: none;
}
}




.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

</style>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Inconsolata|Oswald');
*{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@font-face {
  font-family: "Cambria";
  src: local("Cambria"), url(./fonts/Cambria.ttf) format("truetype");
}
</style>

